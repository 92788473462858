.ant-modal-body {
  border-radius: 10px;
}

.ant-modal-content {
  border-radius: 10px;
  // width: 140%;
}

.ant-modal-centered .ant-modal {
  height: unset !important;

  .ant-modal-content {
    height: 600px;

    .ant-table-body {
      height: 237px;
    }
  }
}

.ant-pagination-options {
  display: none;
}