.coupon-master-page{
  height: 100%;
  p{
    margin: 0px;
  }
  .coupon-header{
    padding: 0px 41px 10px 0px;
    justify-content: space-between;
    display: flex;
    align-items: baseline;
    .header-name{
      font-weight: 500;
      font-size: 18px;
      color: #37434E;
    }
    .create-button{
      background: #2D81F7;
      font-weight: 500;
      font-size: 13px;
      color: #FFFFFF;
      border-radius: 2px;
    }
  }
  .coupon-content{
    background: #FFFFFF;
    border-radius: 6px;
    height: 87%;
    .coupon-empty{
      height: 100%; 
      display: flex;
      justify-content: center;
      align-items: center; 
      text-align: center;
      p{
        margin: 0;  
      }
      .coupon-empty-header{
        font-weight: 500;
        font-size: 24px;
        color: #1B2228;
        padding: 45px 0px 10px 0px;
      }
      .coupon-empty-content{
        font-weight: 500;
        font-size: 13px;
        color: #60717A;
        padding-bottom: 28px;
        padding-right: 20px;
      }
      .coupon-empty-button{
        font-weight: 500;
        font-size: 13px;
        color: #FFFFFF;
        background: #2D81F7;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
      }
    }
  }
}

.discount-wrapper{
  display: flex !important;
  justify-content: space-between !important;
  .discount-percent{
    width: 48% !important;
  }
  .discount-value{
    width: 48% !important;
  }
}
.date-pick{
  .ant-picker{
    width: 100%;
    margin-top: 6px;
  }
  margin-bottom: 15px;
}