.new-bundle-header{
  display: flex;
  .bundle-heading{
    padding-left: 26px;
    font-size: 13px;
    font-weight: 500;
  }
}
.new-bundle-content{
  height:100%;
  display:flex;
  justify-content:center;
  margin-top:10%;
  p:nth-child(1){
    font-size: 16px;
    font-weight: 600;
    color: #021F55;
  }
  .new-bundle-label{
    font-size: 12px;
    font-weight: 500;
  }
  Button{
    margin-top: 22px;
  }
}
