$normal-text-base-color:#1B2228;
$header-Text-base-color:#021F55;
$primary-submit-button-background-color:#2D81F7;
$primary-submit-button-color:#fff;
$primary-cancel-button-background-color:#F5F5F5;
$primary-cancel-button-color:#1B2228;
$primary-header-color:#37434E;
$secondary-header-color:#1B2228;
$table-content-color:#242424;
$primary-link-color:#2D81F7;
$primary-border-color:#E1DFDD;
$primary-white :#FFFFFF;
$border-grey-color :#E1E7EA