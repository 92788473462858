.quesitonBank-button{
  #newQuestion{
    font-size:13px !important;
    font-weight:500 !important;
  }
}
.footer-button{
  display: flex;
  gap:4% !important;
  width: 60% !important;
  .primary-submit-button {
    width: 30% !important;
  }
  .reject-btn {
    width: 30% !important;
  }
  .primary-cancel-button {
    width: 30% !important;
  }

  #saveButton{
    margin: 5px 31px 5px 60px;
  }
}
.image-label{
  padding: 5px 0px;
}
.solution{
  padding: 10px 0px;
    margin-top: 16px;
    border-top: 1px solid #f0f0f0;
}
.reject-remarks{
  padding: 20px 0px;
  color: red;
}