.course-overview-main{
    overflow-y: auto;
    height: calc(100vh - 202px);
}

.cluster-course {
    display: flex;
}

#cluster-course {
    width: 70%;
}

#cluster-basic-content {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    padding-right: 50px;

    #about {
        height: 130px;
    }

    .cluster-thumbnail {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 30%;

        .Thumbnail {
            font-family: "Montserrat";
            font-size: 13px;
            font-weight: 500;
            line-height: 15.85px;
            color: #1B2228;
        }

        p {
            font-family: "Montserrat";
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: #1B2228;




        }

        .thumbnail-content {
            border: 1px dashed #B1BFD0;
            display: flex;
            flex-direction: column;
            height: 150px;
            align-items: center;
            justify-content: center;




            .thumbnail-image {
                width: 44px;
                height: 44px;
            }

            .thumbnail-para {
                font-family: "Montserrat";

            }

            .browse-image {
                cursor: pointer;
                color: #1F4690;
                font-family: "Montserrat";

                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                margin-left: 2px;
            }
        }
    }

    .border-undertext {
        display: flex;
        gap: 6px;
        justify-content: space-between;

        .border-para {
            font-family: "Montserrat";
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            color: #37434E;

        }
    }

}