    .price-container {
        padding: 0px 24px 0 !important;
        height: calc(100vh - 202px);
        overflow-y: scroll;

        .price-head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 21px;

            .price-head-content {
                display: flex;
                flex-direction: row;
                align-items: center;

                .price-head-text {
                    font: 600 16px/20px 'Montserrat';
                    color: #1B2228;
                }
            }

            .settingIcon-icon {
                cursor: pointer;
            }

        }

        .price-accord {
            margin-bottom: 10;

            .price-topic-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .price-topic-head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 600;
                    }
                }

                .price-topic-month {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 600;
                        padding-right: 17px;
                    }
                }
            }

            .price-subtopic-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .price-subtopic-head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                        text-align: left;
                    }
                }

                .price-subtopic-month {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                        padding-right: 17px;
                    }
                }
            }

            .price-class-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .price-class-head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                        text-align: left;
                    }
                }

                .price-class-month {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                        padding-right: 17px;
                        text-align: left;
                    }
                }
            }

            .price-content-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .price-content-head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                        text-align: left;
                    }
                }

                .price-content-month {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                        padding-right: 17px;
                        text-align: left;
                    }
                }
            }

            .least-price {
                display: flex;
                column-gap: 8px;
            }
        }

        .price-form {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .price-form-head {
                padding-top: 8px;
                padding-left: 12px;
                padding-bottom: 16px;
                padding-right: 12px;
                background-color: #ffa4431a;
                height: 52px;
            }
        }

        .overall-price-content {
            display: flex;
            justify-content: space-between;

            .topic-content {
                display: flex;
                flex-direction: row;

                .topicImage-icon {}

                p {}
            }

            .topic-setting {
                display: flex;

                .month-price {}

                .settingIcon-icon {}
            }
        }

        .capitalize {
            text-transform: capitalize;
        }
    }

    .price-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .price-form-head {
            padding-top: 8px;
            padding-left: 12px;
            padding-bottom: 16px;
            padding-right: 12px;
            background-color: #ffa4431a;
            height: 52px;

            .price-form-card {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
            
                .price-form-card-head {
                    display: flex;
                    flex-direction: column;
                    row-gap: 6px;
                    text-transform: capitalize;
            
                    .title {
                        font: 600 13px/15px 'Montserrat';
                        color: #021F55;
                    }
            
                    .price-form-card-content {
                        font: 400 12px/16px 'Montserrat';
                    }
                }
            
                .price-plan {
                    p {
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 600;
                        text-align: left;
                        color: #2A569E;
                    }
                }
            }
            
            .custom-form-card {
                align-items: center;
                .price-form-card-head {
                    row-gap: 0;
                }
            }
            
        }

        .form-control {
            .price-form-add {
                display: flex;
                flex-direction: row;
                gap: 14;

                p {
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                    text-align: left;
                    color: #0F6FDE;
                    text-decoration-line: underline
                }
            }

            .price-free {
                display: flex;
                flex-direction: row;
                gap: 14px
            }
        }

        .price-plan-card {
            .price-plan-text {
                font-family: 'Montserrat';
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                color: #2A569E;
                padding-bottom: 16px;
            }

            .price-plan-subscription {
                padding: 8px;
                gap: 12px;
                border-radius: 4px;
                background-color: #F9F9F9;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 38px;
                margin-bottom: 12px;

                .price-plan-subscription-head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .price-plan-subscription-month {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    .price-plan-subscription-head {
                        display: flex;
                        gap: 2px;
                        color: #2A569E;
                        font-family: 'Montserrat';
                        font-size: 13px;
                        font-weight: 500;
                    }

                }
            }
        }
    }

    .price-plan {
        p {
            font-family: 'Montserrat';
            font-size: 13px;
            font-weight: 600;
            text-align: left;
            color: #2A569E;
        }
    }