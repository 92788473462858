.ImageUpload {
    .ant-upload {
        margin-top: 7px;
        width: 100%;

        .attachment-upload-button {
            border: dashed 2px #8181816b;
            width: 100%;
            height: 38px;

            .browse-and-upload {
                color: #2A569E;
                margin-left: 6px;
            }
        }
    }

    .ant-upload-list-item-name {
        color: rgba(0, 0, 0, 0.85) !important;
    }
}

.attachment-upload-form {

    .custom-thumbnail-container {
        padding: 20px;
        border: 1px solid #B8C3C9;
    }

    .custom-upload-list-item {
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFEDE0;
        border: 1px solid #F8CBAB;
        border-radius: 7px;
        padding: 4px 12px;
        margin-top: 7px;

            .upload-file-container {
                display: flex;
                align-items: center;
                column-gap: 8px;

                .file-icon {
                    .file-type-icon {
                        width: 24px !important;
                    }
                }

                .file-details {
                    flex: 1;
                    column-gap: 8px;
                }

                .file-name {
                    display: block;
                    width: 227px;
                    color: rgba(0, 0, 0, 0.85);
                    font: 500 12px/19px 'Montserrat';
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .file-size {
                    font: 400 11px/ 15px 'Montserrat';
                    color: #7A8B94;
                }
            }

            .remove-icon {
                width: 20px !important;
                height: 20px;
                cursor: pointer;
                color: #d4380d;
            }
        }
}