p{
    margin: 0px;
}

.dashboard-page-container{
    .dashboard-page-wrapper{
        padding: 6px;
        .header-content{
            font-weight: 600;
            font-size: 18px;
            color: #1B2228;
            padding: 0px 0px 14px 0px;
        }
        .body-content{
            .headers{
                font-weight: 500;
                font-size: 12px;
                color: #37434E;
                padding: 0px 0px 14px 0px;

            }
            .card-content{
                background: #FFFFFF;
                border: 1px solid #E1E7EA;
                border-radius: 6px;     
                padding: 15px 30px; 
                width: 75%;    
                min-height: 116px;
                .status{
                    font-weight: 500;
                    font-size: 14px;
                    color: #60717A;
                    padding: 6px 0px;

                }    
                .pending-for-approval{
                    width: 95%;
                    text-align: center;
                    cursor: pointer;
                    .total{
                        font-weight: 600;
                        font-size: 28px;
                        color: #2D81F7;
                    }  
                }
                .pending{
                    width: 95%;
                    text-align: center;
                    cursor: pointer;

                    .total{
                        font-weight: 600;
                        font-size: 28px;
                        color: #FFA443;
                    }  
                }
                .rejected{
                    width: 95%;
                    text-align: center;
                    cursor: pointer;

                    .total{
                        font-weight: 600;
                        font-size: 28px;
                        color: #FF4343;
                    }  
                }
                .divider{
                    .ant-divider-vertical {
                        border: 1px solid #B8C3C9;
                        height: 71px;

                    }
                }
                .col-wrapper{
                    display: flex;
                }
                

            }
            .question-container{
                padding: 0px 0px 16px 0px;
            }
            .chapter-container{
                padding: 0px 0px 16px 0px;
            }
            .subject-container{
                padding: 0px 0px 16px 0px;
            }
            .exam-container{
                padding: 0px 0px 16px 0px;
            }
            .test-container{
                padding: 0px 0px 16px 0px;
            }

        }
    }
}