@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
  font-weight: bold;
}
#root {
  font-family: "Montserrat";
}
body {
  font-family: "Montserrat";
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.subject-master-page .ant-table-thead > tr > th  {
  background: none;
    }

.student-page .ant-table-thead > tr > th  {
  background: none;
}
.student-page .ant-table-pagination.ant-pagination {
  margin-top: 0px;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0px 32px;
}

.student-page .studentHeader{
  display: flex;
  justify-content: space-between;
  padding:0px 0px 8px 0px;
}

.admin-page .ant-table-thead > tr > th  {
  background: none;
}
.admin-page .ant-table-pagination.ant-pagination {
  margin-top: 0px;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0px 32px;
}

.admin-page .adminHeader{
  display: flex;
  justify-content: space-between;
  padding:0px 0px 8px 0px;
}


/* .student-button .action-button.ant-btn {
  background: '#E5E5E5' !important;

}  */
/* .subject-master-page .ant-table-pagination.ant-pagination { */
/* margin-top: 0px; */
/* position: fixed; */
/* right: 0;
    bottom: 0;
    padding: 0px 32px;
    }
} */
