.plan-pricing-container {
    margin-top: 16px;

    .plan-pricing-title {
        font: 600 14px / 17px 'Montserrat', sans-serif;
        color: #2A569E;
        margin-bottom: 12px;
    }

    .pricing-list {

        .pricing-item {

            display: flex;
            justify-content: space-between;
            padding: 8px 24px 8px 11px;
            height: 38px;
            border-radius: 4px 0px 0px 0px;
            background: #F9F9F9;
            align-items: center;
            margin-bottom: 12px;

            .subscription-container-left {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .subscription {
                    font: 500 12px / 19px 'Montserrat', sans-serif;
                    color: #131E29;
                }
            }

            .subscription-container-right {
                display: flex;
                align-items: center;
                column-gap: 32px;

                .subscription-tooltip {
                    cursor: pointer;
                    text-transform: capitalize;
                    display: flex;
                    column-gap: 10px;
                }

                .subscription-with-price {
                    font: 500 13px / 15px 'Montserrat', sans-serif;
                    color: #2A569E;
                    max-width: 80px;
                    width: max-content;
                    text-transform: capitalize;
                }

                .close-icon {
                    height: 20px;
                    width: 20px !important;
                    cursor: pointer;
                }
            }
        }
    }

}