.pricing{
  position: relative;
  .errorMessage{
    bottom: -20px;
  }
  .ant-radio-group{
    display: contents;
  }
  
  .ant-radio-wrapper{
    display: flex;
    flex-direction: row-reverse;
  }
      display: flex;
    .pricing-free{
      p:first-child{
        font-weight: 600;
        font-size: 16px;
        color: #021F55;
      }
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #B8C3C9;
      border-radius: 6px;
      margin: 0px 16px 0px 24px;
      padding:13px;
      background: rgba(255, 255, 255, 0.1);
    }
    .pricing-payment{
      p:first-child{
        font-weight: 600;
        font-size: 16px;
        color: #021F55;
      }
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #B8C3C9;
      border-radius: 6px;
      margin: 0px 16px 0px 0px;
      padding: 13px;
    }
    .payment{
      p:first-child{
        font-weight: 600;
        font-size: 16px;
        color: #021F55;
      }
      border-radius: 6px;
      margin: 0px 16px 0px 24px;
      padding:13px;
      background: rgba(7, 189, 129, 0.1);
      border: 1.5px solid #07BD81;
    }
}

.pricing-details{
  padding:28px 0px 16px 24px;
  .pricing-details-header{
    font-weight: 500;
    font-size: 14px;
    color: #2D81F7;
    padding: 10px 0px;
  }
  .test-price{
    position: relative;
    p{
      font-weight:500;
      font-size: 12px;
      padding-top: 16px;
    }
    Input{
      width: 25%;
    }
  }
}

.overall-pricing{
  .subscription-details{
    .ant-checkbox-group{
      padding-top: 17px;
    }
    padding:20px 0px 16px 24px;
    .subscription-details-header{
      font-weight: 500;
      font-size: 14px;
      color: #2D81F7;
    }
    .subscription-radio{
      padding-top: 16px;
    }
    .subscription-checkbox{
      padding-top: 17px;
      padding-left: 28px;
    }
  }
}
.submit-buttons{
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  top: 40%;
}
