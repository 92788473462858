.video-content-btn{
    margin: 0;
}

.input-with-image {
    position: relative;
    width: 300px;
    /* Adjust width as needed */
}

.input-field-video {
    flex: 1; /* Allow input to expand and fill remaining space */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 25px;
}

.add-icon {
    position: absolute;
    top: 50%;
    // right: 10px; /* Adjust right position as needed */
    transform: translateY(-50%);
    width: 20px;
    /* Adjust width as needed */
    height: 20px;
    /* Adjust height as needed */
    cursor: pointer;
    z-index: 1;
}

.label-othervideo {
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #1B2228;
    padding-top: 12px;

}