@import "variables.scss";

.ant-layout-header {
  background: #fff !important;
}

.logo {
  text-align: center;
  margin: 12px 0px 10px 0px;
}

.ant-menu-submenu {
  .ant-menu {
    background: #fff !important;

    .ant-menu-item {
      padding-left: 30px !important;

      .nav-text {
        font-size: 13px !important;
      }
    }
  }
}

.ant-layout-header {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 99;
}

.ant-layout-sider-children {
  background: #fff;
  height: 100vh !important;
  position: sticky;
  top: 0;
  width: 220px;
}

.ant-layout-sider {
  max-width: 220px;
  min-width: 220px;
  width: 220px;
}

.site-layout-slider {
  background: #fff !important;
  font-weight: 500;
  font-size: 12px;

  .ant-layout-sider-children {
    ul {
      font-size: 12px !important;
    }
  }
}

.nav-text {
  padding-left: 12px;
}

.ant-layout-sider-trigger {
  display: none;
  background: #fff !important;

  span {
    color: #000;
  }
}

.user-management-page {
  padding: 10px;
}

.logo-image {
  background: #2f4bde;
}

.ant-row {}

.placeholder {
  width: 320px;
}

.textaccount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcometext {
  display: flex;
  justify-content: center;
  align-items: center;
}

.groupimage {}

.centumlogo {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.logintext {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundcolor {

  background-color: #2f4bde;
}

.ant-image-img {
  width: 90% !important;
}

.user-image {
  .ant-image-img {
    width: 28px !important;
  }
}

.ant-image {}

.register {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: center;
}

.component-layout-main {
  height: 100%;

  .component-layout {
    height: 100%;

    .component-menu-heading {
      text-align: center;
      margin: 12px 0px;
      font-weight: 600;
      color: #021f55;
      font-size: 14px;
    }

    .ant-divider {
      margin: 0px;
    }
  }
}

.form-control {
  .required {
    color: red;
  }

  padding: 4px 0px;
  font-size: 13px !important;
  color: $normal-text-base-color;

  label {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 15px;
    color: #1b2228;
  }

  .ant-select {
    margin: 6px 0px;
  }

  textarea {
    margin: 6px 0px;
  }

  Input {
    margin: 6px 0px;
  }

  .ant-radio-group {
    margin: 6px 0px;
  }

  .quill {
    margin: 6px 0px;

    .ql-container {
      height: 200px;

    }
  }

  p {
    margin: 0px;
  }

  #validDateTime {
    margin: 0px;
  }
}

.subject-master-page {
  .add-new-subject {
    .ant-btn {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .subject-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;

    .subject-header-wrapper {
      display: flex;
      align-items: center;

      .list-dropdown {
        margin: 0px 10px;
        width: 200px;
      }

    }

    .download-new-btn {
      display: flex;
      align-items: center;
    }
  }

  .import {
    margin-right: 33px;

    Image {
      margin-right: 3px;
    }
  }

  .ant-tabs-ink-bar {
    border-bottom: 4px solid #2D81F7;
    ;
  }

  .ant-tabs-tab-btn {
    color: #5A7182 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #12344D !important;
    text-shadow: 1px 0px 0px black;
    font-size: 14px !important;
  }

  .ant-tabs-tab {
    padding: 17px 42px 11px 42px !important;
    font-size: 14px;
    color: #5A7182 !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;
  }
}

.subscription-master-page {
  .add-new-subscription {
    margin-right: 41px;

    .ant-btn {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .subscription-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .import {
    margin-right: 33px;

    Image {
      margin-right: 3px;
    }
  }
}

.chapter-master-page {
  .add-new-chapter {
    .ant-btn {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .chapter-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;

    .chapter-header-wrapper {
      display: flex;
      align-items: center;

      .list-dropdown {
        margin: 0px 10px;
        width: 200px;
      }
    }

    .download-new-btn {
      display: flex;
      align-items: center;
    }
  }

  .import {
    margin-right: 33px;

    Image {
      margin-right: 3px;
    }
  }

  .ant-tabs-ink-bar {
    border-bottom: 4px solid #2D81F7;
    ;
  }

  .ant-tabs-tab-btn {
    color: #5A7182 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #12344D !important;
    text-shadow: 1px 0px 0px black;
    font-size: 14px !important;
  }

  .ant-tabs-tab {
    padding: 17px 42px 11px 42px !important;
    font-size: 14px;
    color: #5A7182 !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;
  }
}

.exam-master-page {

  .add-new-exam {
    // margin-right: 41px;

    .ant-btn {
      font-weight: 500;
      font-size: 14px;
      margin: 0px 0px 0px 9px;
    }
  }

  .exam-header {
    .exam-header-dropdown {
      display: flex;
      align-items: center;

      .list-dropdown {
        width: 200px;
        margin: 0px 10px;
      }
    }

    display: flex;
    justify-content: space-between;
    padding: 0px 0px 6px 0px;

    .download-new-exam-btn {
      display: flex;
      align-items: center;
    }
  }

  .import {
    margin-right: 33px;

    Image {
      margin-right: 3px;
    }
  }
}

.ant-drawer-content-wrapper {
  font-family: "Montserrat";

  .ant-drawer-title {
    font-weight: 600;
    color: #021f55;
    font-size: 16px;
  }

  width: 37% !important;

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    margin: 20px 20px 50px 20px;

    .error {
      color: red;
      font-size: 11px;
    }

    label {
      font-weight: 500;
    }

    Input {}

    .ant-drawer-footer {
      display: flex !important;
      justify-content: end !important;

      .footer-cancel {
        padding: 4px 30px 0px 0px;
        color: #1b2228;
      }
    }
  }
}

.admin-page {
  .adminHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
  }
}

.ant-drawer-footer {
  padding: 0px;
  background-color: #f5f5f5;

  .footer {
    background: #f5f5f5;
    display: flex;
    padding: 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 42px !important;
  }
}

.drawer-body {
  padding: 20px 25px;
}

.student-page {
  .studentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
  }
}

.table-component {
  .ant-table-pagination.ant-pagination {
    margin: 16px 0px 0px 0px;
  }
}

.testMaster-page {
  .test-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    padding-bottom: 10px;

    .test-header-wrapper {
      display: flex;
      align-items: center;

      .list-dropdown {
        margin: 0px 10px;
        width: 200px;
      }
    }
  }

  .all-table-Component {
    .table-header {
      display: flex;
      // background: #fff;
      justify-content: space-between;
      align-items: center;
    }

  }
}

.login-form {
  color: $normal-text-base-color;

  Input {
    width: 320px;
  }
}

.login-button {
  width: 320px;
  padding-top: 52px;
}

.logoimage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-image:nth-child(1) {
    margin: 55px 0px 50px 0px;
  }
}

.username {
  display: grid;
  justify-content: center;
  // padding-top: 49px;
}

.password {
  display: grid;
  justify-content: center;
}

.ant-space-item {
  width: 320px;
  padding-top: 6px;
}

.inputusername {
  height: 65px;
  padding-top: 6px;
  padding-bottom: 20px;
}

.textaccount {
  padding-top: 18px;
}

.input-label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: 'relative';
}

.logintext {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $normal-text-base-color;
}

.buttonlogin {
  width: 320px;
  margin: auto;
  padding: 30px 0px 20px 0px;

}

.welcometext {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: $header-Text-base-color;
}

.forgot-password {
  display: flex;
  justify-content: right;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-right: 160px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #2f4bde;
}

.question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-left: 25px;
  padding-top: 20px;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
  height: 42px;
  border: 1px solid;
  width: 100%;
  position: relative;
  border: 1px solid #e1e7ea;
  border-radius: 3px;
  color: #7a8b94;
  position: relative;
}

.Button {
  display: flex;
}

.buttonSave {}

.buttonCancel {
  padding: 4px 30px 0px 0px;
  background-color: unset !important;
  color: #1b2228 !important;
  border: unset !important;
  box-shadow: none !important;
}

.label-input {
  padding-bottom: 6px;

  label {
    padding-bottom: 0 !important;
  }
}

.Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.Footer {
  position: "sticky";
  bottom: "0";
}

.Inputbox {}

.ant-drawer-body {
  padding: 0px 0px 0 !important;
}

.body-form {
  padding: 0px 24px 0 !important;
  height: calc(100vh - 120px);
  overflow-y: scroll;

  label {
    padding-bottom: 6px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}

.ant-drawer-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 20px;
  color: #021f55;
}

.subject {
  padding-top: 16px;

  &:not(:first-child) {
    padding-top: 20px;
    padding-bottom: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}

.Dropdownbox {
  padding-top: 6px;
}

.type-of-question {
  padding-top: 16px !important;
}

.answer-row-choice {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    padding-left: 16px;
  }
}

.ant-select {
  width: 100% !important;
}

.ant-input-in {
  margin-top: 6px !important;
}

.dl {
  padding-bottom: 16px;
}

.ant-label-input {
  margin-top: 3px;
}

.login-wrapper {
  height: 100vh;
  overflow-y: hidden;

  .ant-row {
    height: 100vh;
  }

  .ant-image-mask {
    display: none !important;
  }

  .login-content-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content-wrapper {
      .forgot-button-wrapper {
        padding: 5px;

        .forgot-text {
          color: #1890ff;
          display: flex;
          justify-content: flex-end;

          a {
            cursor: pointer;
          }
        }

        .forgot-link {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          // color: #37434E;

        }
      }
    }
  }

}

.error {
  color: red;
  font-size: 13px;
  padding-bottom: 10px;
}

.newTest-page {
  .newTest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    .newTest-label {
      .ant-image {
        // margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  .label-content {
    padding-left: 21px;
    font-weight: 500;
    font-size: 14px;
    color: #1b2228;
  }

  .newTest-button {
    display: flex;
    align-items: center;

    .approval-button {
      margin: 0px 5px;

      span {
        color: white;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .newTest-body {
    background: #ffffff;
    height: calc(100vh - 130px);
    overflow-y: scroll;
    z-index: 99;

    .test-basic-content {
      width: 55%;
      padding: 20px 0px 30px 45px;

      .test-basic-header {
        color: #021f55;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .ant-tabs>.ant-tabs-nav {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 3;
    }

    .ant-tabs {
      .ant-tabs-content-holder {
        overflow-y: scroll;
        height: calc(95vh - 233px);
       
      }
    }
  }
}

.Section-body {
  padding: 10px 20px 90px 20px;

  .Section-form-container {
    padding: 21px 26px;
    border: 1px solid #b8c3c9;
    border-radius: 10px;
    margin: 0px 0px 20px 0px;

    .Section-form-Header {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;

      .Header-text {
        margin: 0px;
        color: #021f55;
        font-size: 16px;
      }

      .close-block {
        display: flex;
        justify-content: center;
        align-items: center;

        .sufficient {
          color: green;
          padding: 0px 10px;
        }

        .in-sufficient {
          color: red;
          padding: 0px 10px;

        }

      }

    }

    .Section-form {
      .form-control {
        margin-left: 18px;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #021f55 !important;
  text-shadow: 1px 0px 0px black;
  font-size: 13px;
}

.ant-tabs-tab {
  margin-left: 15px;
  font-size: 14px !important;
  font-weight: 500;
}

.questionBank-page {
  .questionBankHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;

    .questionBankHeaderWrapper {
      display: flex;
      align-items: center;

      .list-dropdown {
        margin: 0px 10px;
        width: 200px;
      }
    }
  }

  .ant-tabs-ink-bar {
    border-bottom: 4px solid #2D81F7;
    ;
  }

  .ant-tabs-tab-btn {
    color: #5A7182 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #12344D !important;
    text-shadow: 1px 0px 0px black;
    font-size: 14px !important;
  }

  .ant-tabs-tab {
    padding: 17px 42px 11px 42px !important;
    font-size: 14px;
    color: #5A7182 !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;
  }
}

.formik-question-option {
  padding: 5px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .formik-input-field {
    flex-grow: 2;
  }

  .ant-checkbox-wrapper {
    .ant-checkbox-input {
      margin: 0px !important;
    }
  }
}

.addOption {
  margin: 0;
  padding: 5px 20px;
}

.addSectionClass {
  cursor: pointer;
  color: #2d81f7;
  margin: 0px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.ant-tabs-tab {
  padding: 17px 26px 11px 26px !important;
  color: #60717a;
  font-weight: 500;
  font-size: 13px;
  margin: 0px !important;
}

.ant-tabs-tab-active {
  padding: 0px;

  .ant-tabs-tab-btn {
    color: #021f55 !important;
    font-weight: 600;
    font-size: 13px;
  }
}

.cancelSection {
  margin: 0;
}

.cancel-Button {
  background: "#f0f2f5";
  border: "none";
  box-shadow: "none";
  color: #1b2228;
  margin: 0px 25px;
  border: 1px solid #e1dfdd;
}

.test-basic-footer {
  position: fixed;
  bottom: 0;
  z-index: 99;
  border-top: 1.5px solid #e3e5ed;
  width: 85%;
  padding: 17px;
  background-color: #fff;

  display: flex;
  justify-content: flex-end;

  .test-basic-footer-button {}
}

.question-selection-footer {
  position: fixed;
  bottom: 0;
  z-index: 99;
  border-top: 1.5px solid #e3e5ed;
  width: 85%;
  padding: 17px;
  background-color: #fff;

  display: flex;
  justify-content: flex-end;

  .question-selection-footer-button {}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.all-table-component {

  .header {
    display: flex;
    background: #fff;
    justify-content: space-between;
    align-items: center;
  }
}

.golbalSearch {
  display: flex;
  justify-content: flex-end;
  // background-color: #fff;
  padding: 16px;
  border-radius: 6px 6px 0px 0px;

  .ant-input-affix-wrapper {
    border-radius: 4px;
  }

  .anticon-search {
    color: #646c7a;
  }
}

.ant-table-body {
  color: $table-content-color !important;
}

.ant-table-thead {
  tr {
    th {
      border-bottom: 5px solid #f0f0f0 !important;
      background-color: #fff !important;
    }
  }
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #021f55;
}

.ant-table {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 20px;
}

.ant-checkbox-inner {
  border-radius: 4px !important;
  border: 1px solid #60717A !important;
}

th.ant-table-cell {
  color: #60717A !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.table-action-link a {
  color: $primary-link-color;
  font-size: 500;
  text-decoration: underline;
  margin: 0px 20px 0px 0px;
}

.table-action-link {
  .ant-image {
    margin: 0px 20px;
  }
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-cell-scrollbar {
  display: none;
}

.ant-table-selection-col {
  width: 36px !important;
}

.ant-table-selection-column {
  padding-left: 15px !important;
}

.primary-header {
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $secondary-header-color;
}

.primary-submit-button {
  background: $primary-submit-button-background-color !important;
  border-radius: 4px !important;
  color: $primary-submit-button-color !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  // line-height: 24px !important;
  height: 32px !important;
  margin: 0px 13px;

  &:hover {
    background: #196DE3 !important;
    color: #FFFFFF;


  }
}

.primary-btn {
  background: $primary-submit-button-background-color !important;
  border-radius: 4px !important;
  color: $primary-submit-button-color !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  height: 32px !important;
  margin: 0px 13px;

  &:hover {
    background: #196DE3 !important;
    color: #FFFFFF;


  }
}

.primary-cancel-button {
  background: #F2F4F5;
  border: 1px solid #B8C3C9;
  border-radius: 4px !important;
  color: #2D81F7 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  height: 32px !important;
  // padding: 3px 20px 5px 20px !important;
  margin: 0px 12px;
  border: 1px solid $primary-border-color;

  &:hover {
    background: #E6EFFD !important;
  }
}

.ant-menu-item-selected {
  background-color: $primary-submit-button-background-color !important;
  border-radius: 6px !important;

  a {
    display: flex;
    align-items: center;
    color: #f9f9f9 !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    .nav-text {
      width: 70%;
    }
  }
}

.ant-menu-inline {
  .ant-menu-item::after {
    border: none !important;
  }

}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: auto !important;
}

.ant-layout-sider-trigger {
  text-align: right !important;
}

.ant-menu-item {
  margin: 0px 6px 0px 4px !important;

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;

    .nav-text {
      width: 70%;
    }
  }
}

.ant-input {
  font-size: 13px !important;
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 20px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-submit-button-background-color !important;
  border-color: $primary-submit-button-background-color !important;
}

.ant-pagination-total-text {
  margin-top: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #646c7a;
}

.ant-radio {
  .ant-radio-checked::selection {}
}

.ant-radio-checked .ant-radio-inner {
  border-color: $primary-submit-button-background-color !important;
  background-color: $primary-submit-button-background-color !important;
}

.ant-radio-inner::after {
  background-color: #fff !important;
}

.add-option {
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #1890ff;
  padding: 10px;
  cursor: pointer;
  text-decoration: underline;
  max-width: max-content;

  &:hover {
    // background-color: #BCD6F9;
    text-decoration: none;

  }
}

.remove-question {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: red;
  padding: 10px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    // background-color: #BCD6F9;
    text-decoration: none;

  }

}

.ant-divider-horizontal {
  // margin: 0px !important;
  min-width: none !important;
  width: auto !important;
}

.divider-class {
  margin: 0px !important;
}

.ant-form label {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.remove-margin {
  margin: 0px !important;
  width: 100%;
}

.ant-space-item {
  padding: 0px;
}

.ant-space.ant-space-vertical {
  gap: 0px !important;
  height: 30px;
  padding-top: 6px;
  padding-bottom: 14%;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;



  .userDetails-container {
    display: flex;
    align-items: center;

  }

  .user-logo {
    width: 28px;
  }

  .login-userDetails {
    font-weight: 500;
    font-size: 13px;

    .name {
      color: #1B2228;
      padding: 6px 10px;
      border-right: 1px solid #B8C3C9;
    }

  }

  .user-image {
    padding: 0px 10px;
    cursor: pointer;

    .roleName {
      font-weight: 500;
      font-size: 13px;
      color: #021F55;
      padding: 6px 10px;
    }
  }

}

.ant-menu-item-selected {
  background-color: #2d81f7 !important;
  border-radius: 6px !important;
}

.loading-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body {
      text-align: center;

      .anticon svg {}

      .ant-spin-text {
        color: #2d81f7;
        font-size: 16px;
        font-weight: 900;
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.noData {
  color: #1b2228;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  padding: 10px;
}

.ant-spin-dot {
  width: 35px;
  height: 35px;
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logout-container {
  display: flex;
  align-items: center;

  cursor: pointer;

  .nav-text {
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px 0px 12px;
  }
}

.exam-upload-file {
  .exam-image-upload-button {
    background: #e1e7ea;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    color: #1b2228;
    border-color: #e1e7ea;
  }

  .ant-btn:hover {
    background: #e1e7ea;
    border-radius: 3px;
    border-color: #e1e7ea;
    color: #1b2228;


  }

  .ant-btn:focus {
    background: #e1e7ea;
    border-radius: 3px;
    border-color: #e1e7ea;
    color: #1b2228;

  }
}

.ql-snow .ql-tooltip {
  z-index: 1000;
  left: 0px !important;
}

.editor-p-wrapper {
  border: 1px solid #cccccc;

  .mathQuill-editor {
    .mq-editable-field {
      border: 1px solid transparent;
    }

    .mq-root-block {
      padding: 5px;
    }
  }

}

.save-equation {
  .ant-modal-footer {
    display: none;
  }

  .primary-submit-button {
    margin: 10px 0px;
  }
}

.ant-table-column-title {
  font-size: 13px !important;
  color: #60717A !important;
}

.ant-modal-content {
  font-family: 'Montserrat';
  color: #1B2228;

  .ant-modal-footer {
    font-family: 'Montserrat';

    .ant-btn-primary {
      background: #2D81F7 !important;
      // margin: 0px 0px 0px 15px;
    }

    .disabled-button {
      background-color: #BCD6F9 !important;
    }
  }
}

.basic-form {
  .ant-input {
    height: 38px;
  }

  .ant-select-selector {
    height: 38px !important;
  }

  .ant-upload-list-picture .ant-upload-list-item-name {
    color: rgba(0, 0, 0, 0.85);
  }
}

.add-pricing {
  font: 500 13px / 20px 'Montserrat', sans-serif;
  color: #0F6FDE;
  text-decoration: underline #0F6FDE;
  cursor: pointer;
}

.formarray-action-button {
  font: 500 13px / 20px 'Montserrat';
  text-decoration: underline;
  font-family: Montserrat;
  color: #0F6FDE;
  cursor: pointer;
} 

.formarray-action-button.remove {
  color: red;
}

.disabled-button {
  background-color: #BCD6F9 !important;
  color: #FFFFFF;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 32px !important;
  padding: 3px 20px 5px 20px !important;
  margin: 0px 13px;
  border: none !important;
}

#disabled-button {
  background-color: #BCD6F9 !important;
  color: #FFFFFF;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 32px !important;
  padding: 3px 20px 5px 20px !important;
  border: none !important;
}

.answer-option-error-message {
  color: red;
  font-size: 12px;
}

.margin-zero {
  margin: 0px;
}

.MuiIconButton-root {
  padding: 0 !important;
}

.MuiIconButton-root:hover {
  background: none !important;
}

.table-header {
  display: flex;
  // background: #fff;
  justify-content: space-between;
  align-items: center;

  .list-dropdown {
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 91%;

    div {
      width: 300px;
    }
  }

  .approve-btn {
    margin: 0px 27.5px 0px 10px;
    color: #FFF;
    background: #2D81F7;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;

    &:hover {
      background: #196DE3 !important;
      color: #FFFFFF;
    }
  }

  // .approve-btn:hover{
  //   margin: 0px 27.5px 0px 10px;
  //   color: #FFF;
  //   background: #2D81F7;
  //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  //   border-radius: 2px;
  //   font-weight: 500;
  //   font-size: 13px;
  // }
  .reject-btn {
    background: #E1E7EA;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    color: #60717A;
  }
}

.exam-master-page {
  .ant-tabs-ink-bar {
    border-bottom: 4px solid #2D81F7;
    ;
  }

  .ant-tabs-tab-btn {
    color: #5A7182 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #12344D !important;
    text-shadow: 1px 0px 0px black;
    font-size: 14px !important;
  }

  .ant-tabs-tab {
    padding: 17px 42px 11px 42px !important;
    font-size: 14px;
    color: #5A7182 !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;
  }
}

.footer-button {
  .approve-btn {
    margin: 0px 27.5px 0px 10px !important;
    color: #FFF;
    background: #2D81F7 !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;

    &:hover {
      background: #196DE3 !important;
      color: #FFFFFF;
    }
  }

  // .approve-btn:hover{
  //   margin: 0px 27.5px 0px 10px;
  //   color: #FFF;
  //   background: #2D81F7;
  //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  //   border-radius: 2px;
  //   font-weight: 500;
  //   font-size: 13px;
  // }
  .reject-btn {
    background: #E1E7EA;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    color: #60717A;
  }
}

.ant-table-row {
  cursor: pointer;
}

#examField {
  margin-top: 13px;
}

.userRoleExamCancel {
  cursor: pointer;
}

.raise_a_dispute_page {
  .header {
    padding: 3px 0px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }
}

.setPassword-container {

  .header {
    font-weight: 600;
    font-size: 20px;
    color: $header-Text-base-color;
  }

  .logo-container {
    height: 100vh;
    background-color: #2f4bde;
    display: flex;
    justify-content: end;
  }

  .password-content-wrapper {
    height: 100vh;

    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .form-wrapper {
        width: 400px;

        .form-control {
          margin-top: 10px;

        }

        .navigate {
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: #1b2228;
          margin-top: 20px;
        }
      }
    }

  }
}

.forgotPassword-container {

  .header {
    font-weight: 600;
    font-size: 20px;
    color: $header-Text-base-color;
  }

  .logo-container {
    height: 100vh;
    background-color: #2f4bde;
    display: flex;
    justify-content: end;
  }

  .forgot-content-wrapper {
    height: 100vh;

    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .form-wrapper {
        width: 400px;

        .form-control {}

        .navigate {
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: #1b2228;
          margin-top: 20px;
        }
      }
    }

  }
}

.breadCrumb-Data {
  padding: 10px 0px;
  display: none
}

.reject-button {}

.BreadCrumb-container {
  .content {
    text-transform: capitalize;
    cursor: pointer;
  }
}

.question-upload-excel {
  .file-upload {}

  .download-upload {
    padding: 20px 0px 0px 0px;
  }
}

.question-text-wrapper {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;

  img {
    width: 50px;
    height: 50px;
  }

  table,
  th,
  td {
    border: 1px solid rgba(73, 72, 72, 0.85) !important;
    border-collapse: collapse;
  }
}

.question-option-wrapper {
  padding: 6px 0px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #77b7f3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}

.ant-table-tbody>tr>td {
  padding: 9px 16px !important;
  line-height: 19px !important;
}

.wrs_editor {
  min-height: 200px;
  max-height: 200px;
}

.ant-popover.ant-popover-placement-bottom {
  padding: 0px;
}

.pageNotFound-wrapper {
  width: 100%;
  height: 100vh;

  .pageNotFound-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ant-image {
      margin-top: 165px;
      margin-bottom: 22px;
    }

    .goToHome {
      button {
        background: #2D81F7;
        color: #FFFFFF;
        border-radius: 4px;
      }
    }
  }
}

.exam-group-container, .educator-master-container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }

  .all-table-component {}
}

.highlight-text {
  color: #021F55;
  font-weight: 600;
  text-transform: capitalize;
}

.draft-state {
  color: black;
  background-color: orange;
  padding: 3px;
  margin-right: 3px;
  font-weight: 600;
  border-radius: 4px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  min-height: 200px;
}

.add-equation-wrapper {
  padding: 10px 0px;
}

.institution-drawer {
  .ant-drawer-content-wrapper {
    width: 878px !important;
  }
}

.studentDetails-main-radioButton {
  .ant-radio-group.ant-radio-group-outline {
    display: flex;
  }
}

//Course master
.course-header {
  font-weight: 600 !important;
  // padding-left: 36px !important;
  align-items: center;
  text-transform: capitalize;
}

/* course page */

.ant-popover-placement-bottomRight {
  padding-top: 0px !important;
}

.ant-popover-inner-content {
  padding: 8px !important;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .ant-image {
    margin-right: 6px;

    p {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .menu-option-icon {
    display: flex;
    padding: 7px 4px;
    align-items: center;
    cursor: pointer;
  }

  .edit {
    border-bottom: 1px solid #9e9e9edd;
  }
}

.delete-confirmation-modal {
  width: 22% !important;

  .ant-modal-header {
    padding: 10px;

    .ant-modal-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .ant-modal-body {
    padding: 10px;

    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      font-size: 14px;
      height: 26px;
      padding: 0px 9px;
    }
  }

  .ant-modal-close {
    top: 10px;
    right: 5px;

    span {
      width: 24px;
      height: 24px;
      line-height: 24px;

    }
  }
}

.course-body-container {
  .course-body-wrapper {
    height: calc(100vh - 207px);
    overflow: scroll;

    // padding: 0px 20px 20px 20px;
    /* course card */
    .new-course-container {
      position: relative;
      cursor: pointer;
      background: $primary-white;
      margin: 0px 18px 12px 0px;
      border-radius: 8px;
      border: 1px solid #2D81F7;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      height: 108px;
      display: flex;
      padding: 10px 0px 10px 20px;
      border: 1px solid $border-grey-color;

      .course-name {
        margin-top: 15px;
        color: #1B2228;
        width: 85%;

        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          font-family: 'Montserrat';
          text-transform: capitalize;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .number-batch {
          margin-top: 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }
      }

      .right-image-icon {
        position: absolute;
        right: 0;



        .course-ellipse {
          display: flex;
          justify-content: end;
          margin-right: 6px;

          .ant-image {
            padding: 4px;
            border-radius: 50%;

            img {
              width: 22px;
              height: 22px;
            }
          }

          .ant-image:active {
            background-color: #9a9a9a47;
          }
        }

        .course-image {
          position: relative;
          bottom: -5px;
        }
      }

      p {
        margin: 0px;
      }

      .course-text {
        width: 70%;

        .course-name {
          cursor: pointer;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #1B2228;
          ;
          margin: 0px 0px 0px 16px;
          // width: 55%;
          text-transform: capitalize;
        }
      }

      .course-icon-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .course-ellipse {
          height: 50px;
        }
      }
    }

  }

  //   .action-menu-container{
  //     background: #FFFFFF;
  // /* Gray 5 */

  // border: 1px solid #E0E0E0;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  // border-radius: 4px;
  // width: 98px;
  // height: 72px; 
  // padding: 8px;
  // box-sizing: border-box;
  // position: absolute;
  //     z-index: 113;
  //     top: 191px;
  //     left: 346px;
  //   }
}

.course-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
}

.coursePage-container {
  height: calc(100vh - 160px);
  // padding: 27px 22px 38px 22px;
  background: #FFFFFF;
  border-radius: 6px;

  // width: 1036px;
  .coursePage-wrapper {
    .batch-container {
      // padding-top: 50px;
      // padding-left: 26px;
    }
  }

}

.batch-body-container {
  background: #FFFFFF;
  border: 0.886792px solid #B8C3C9;
  border-radius: 8px;
  width: 220px;
  height: 63px;

  .batch-body {
    padding: 10px 39px 10px 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .batch-content {
      width: 85%;
    }

    p {
      margin: 0px;
    }

    .batch-name {
      color: #1B2228;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .batch-total {
      color: #1B2228;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

//purchasedTest
.purchasedTest-container {
  height: calc(100vh - 160px);
  padding: 10px 0px;
  border-radius: 6px;
  overflow-y: scroll;

  .purchasedTest-wrapper {
    .purchased-container {
      background: #FFFFFF;
      border-radius: 10px;
      // width: 324px;
      padding: 16px 18px;
      margin: 0px 18px 12px 0px;
      border: 1px solid #ddd !important;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

      .purchased-body {
        p {
          margin: 0px;
        }

        .test-name {
          font-weight: 500;
          font-size: 11px;
          line-height: 20px;
          color: #2D81F7;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        .test-group-name {
          padding-top: 2px 0px 10px 0px;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #1B2228;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .split-container {
          display: flex;
          font-weight: 500;
          font-size: 11px;
          color: #37434e;
          padding: 6px 0px;
        }

        .published-footer {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .publish-footer {
            // padding-top: 10px;

            .publish-button {
              // display: flex;
              background: #07BD81 !important;
              border-radius: 2px !important;
              margin: 0px !important;
            }
          }

          .test-remains-container {
            background: rgba(235, 87, 87, 0.14);
            border-radius: 30px;
            padding: 4px 14px;
            color: #EB5757;
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            height: 27px;
            display: flex;
            align-items: center;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.unpublished-divider {
  margin: 8px 0px !important;
  border: 1px solid #B8C3C9;

}

.assign-date-container {
  display: flex;

  Input {
    width: 230px;
  }
}

//batchList-container
// .batchList-container{
//   .batchList-wrapper{
//     .batchList-sider{
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//     }
//   }
// }
.upload-container {
  border: 1px dashed #B8C3C9;
  border-radius: 10px;
  height: 148px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text-upload {
    width: 184px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #60717A;

    p {
      margin: 0px !important;
    }
  }

  .choose-button {
    margin-top: 16px;
  }
}

.footer-text {
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 20px;
  color: #1B2228;

  span {
    color: #2F80ED;
    cursor: pointer;
  }
}

//test-result-page
.test-result-container {
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  border-radius: 5px;
  // width: 978px;
  height: 112px;
  margin: 27px 26px 22px 31px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .test-details {
    padding: 10px 20px 10px 20px;

    p {
      margin: 0px !important;
    }

    .test-date {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #039464;
    }

    .test-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #021F55;
      padding-top: 12px;
    }

    .split-container {
      display: flex;
      font-weight: 500;
      font-size: 11px;
      color: #37434e;
      padding: 6px 0px;
    }

    .language-content {
      display: flex;
      align-items: center;

      .language-image {
        margin-right: 6px;
      }

      .language-text {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #37434E;
      }
    }
  }

  .attempted-students-details {
    margin: 14px 59px 14px 59px;
    padding: 7.5px 20px 7.5px 20px;

    .attempted-text {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #37434E;
    }

    .total-attempted-students {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #021F55;
      padding: 8px 0px;
    }
  }
}

.split-container {
  display: flex;
  font-weight: 500;
  font-size: 11px;
  color: #37434e;
  padding: 6px 0px;
}

//Test Results details 
.resultsDetails-container {
  padding: 10px 25px 0px 16px;

  .results-details {
    border: none;
    margin: 0px !important;

    .test-details {
      padding: 10px 20px 10px 0px;
    }
  }
}

// assign test in unblished test
.assign-test-container {
  display: flex;

  // p {
  //   color: #FFFFFF;
  // }

  background: #3F99F2;
  border-radius: 10px;
  // height: 113px;
  flex-direction: column;

  .assign-test-wrapper {
    background: #F0FDF4;
    display: flex;
  }

  .assign-test-body {
    padding: 18px;
    width: 80%;

    .test-group-name {
      color: #1B2228;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .test-name {
      color: #60717A;
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
    }

    .split-container {
      color: #60717A;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .image-body {
    display: flex;
    align-items: center;
  }

  .test-remains-body {
    background: #07BD81;
    border-radius: 0px 0px 10px 10px;
    height: 33px;
    padding: 10px 16px;
  }
}

.published-test-container {
  display: flex;

  p {
    color: #FFFFFF;
  }

  background: #3F99F2;
  border-radius: 10px;
  height: 113px;

  .published-test-body {
    padding: 18px;
    width: 80%;
  }

  .published-image-body {
    display: flex;
    align-items: flex-end;
  }
}

// 
.publishedTest-container {
  height: calc(100vh - 160px);
  padding: 10px 0px;
  border-radius: 6px;
  overflow-y: scroll;

  .publishedTest-wrapper {
    .published-container {
      background: #FFFFFF;
      border-radius: 5px;
      // width: 340px;
      height: max-content;
      margin: 0px 17px 17px 0px;
      padding: 16px;
      border: 1px solid #ddd !important;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

      p {
        margin: 0px !important;
        text-transform: capitalize;
      }

      .test-name {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #039464;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .test-group-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #021F55;
        padding-top: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .test-details-container {
        padding-top: 7px;

        .language-content-published {
          display: flex;
          padding-bottom: 11px;
          align-items: center;

          .language-text {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #37434E;
            padding-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }


      }

      .share-footer {
        .share-button {
          margin: 0px !important;
        }

        .view-test-result-button {
          background: #07BD81 !important;
        }
      }
    }
  }
}

// individual-instution-content
.individual-instution-content {
  border: 1px solid #E1E7EA;
  border-radius: 10px;
  background: #FFFFFF;
  height: 566px;

  .individual-instution-tab {
    // margin: 20px;
  }
}

.individual-details-content {
  border: 1px solid #B8C3C9;
  border-radius: 10px;
  padding: 20px;
  height: 209px;
  margin: 20px;

  .individual-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .basic-details {
      color: #2D81F7;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .individual-details-header-right {
      display: flex;
      background: #E1E7EA;
      border-radius: 30px;
      padding: 4px 12px;
      align-items: center;
      cursor: pointer;

      .edit-text {
        color: #37434E;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        padding-left: 4px;
      }
    }
  }

  .individual-details-body {
    // padding-top: 8px;

    .personal-content {
      color: #60717A;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      padding-top: 10px;
    }

    .personal-answer {
      color: #1B2228;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding-top: 10px;
    }
  }
}

//getbatch list page
.batchList-body-container {
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  border-radius: 10px;
}

.batchList-sider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
}

.dynamic-container {
  justify-content: flex-end;
  display: flex;
  padding-right: 25px;

  .userDetails-container {
    display: flex;
    align-items: center;
  }

  .login-userDetails {
    margin-right: 7px;
    font-size: 13px;
    font-weight: 500;
  }
}

.table-rank-icon {
  .ant-image {
    .ant-image-img {
      width: 100% !important;
    }
  }
}

.institute-bulk-upload-filename {
  display: flex;
  align-items: center;

  .ant-image-img {
    padding-left: 15px;
    cursor: pointer;
  }

  span {
    padding-left: 15px;
  }
}

.app-question {
  font-size: 30px;
}

.radio-btn {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* CSS for the radio button labels */
.radio-btn label {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

/* Optional: Style the red asterisk for required fields */
.radio-btn span {
  color: red;
  margin-left: 5px;
}

/* CSS for the radio buttons themselves */
.radio-btn input[type="radio"] {
  margin-right: 5px;
  cursor: pointer;
  /* Add a pointer cursor to indicate interactivity */
}

.blurred-button {
  filter: blur(0.5px) !important;
  background-color: #D3D3D3 !important;
  border: solid #D3D3D3 !important;
  color: #000 !important;

  &:hover {
    background-color: #D3D3D3 !important;
  }
}

.publish-button {
  display: flex;
  gap: 4px;

}

.status-active {
  width: 110px !important;
}
.status-active-unpublished {
  padding: 0px 4px;
}

.inactive-status {
  filter: blur(0.5px) !important;
  background-color: #d9d9d97d !important;
  border: solid #d9d9d97d !important;
  color: #000 !important;

  &:hover {
    background-color: #d9d9d97d !important;
  }
}

.table-action-link {
  display: flex;

  a {
    margin: 0px;
  }
}

.index-id {
  display: flex;
}


.community-container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }

  .all-table-component {}
}

.exam-upload-file {
  margin: 10px 0px;
}

.add-selection-community {
  margin: 6px 0px;
}

.community-social-media {
  .form-control {
    margin: 6px 0px;
  }
}

body {
  overflow: hidden;

  .ant-layout { 
    .ant-layout-sider {
      .ant-layout-sider-children {
        .logo {
          display: flex;
          position: fixed;
        }

        ul {
          margin-top: 65px;
          overflow-y: scroll;
          height: calc(100% - 100px);

          .ant-menu-sub {
            margin-top: auto;
          }
        }
      }
    }

    .site-layout {
      .ant-layout-content {
        .dashboard-page-container {
          .body-content {
            overflow-y: scroll;
            height: calc(100vh - 140px);
          }
        }
      }
    }
  }
}

.coupon-content {
  background: none !important;

  .golbalSearch {
    padding: 16px 0px;
  }
}

.institution-content {
  background: none !important;
}

.download-question {
  margin-bottom: 10px;
}

.coupon-header {
  padding: 0px !important;

  .coupon-header-devision {
    display: flex;

    .create-coupon {
      margin-left: 15px;
    }
  }
}

.check-radio-button {
  margin-top: 16px;
}

.test-batch-assign {
  .form-control {
    margin-top: 16px;
  }
}

.ant-switch-checked {
  background-color: #33344A !important;
}

.cluster-normalPage{
  display: flex;
  align-items: center;
  gap: 20PX;
}
.ant-layout {
  background: none !important;
}

// #cluster-course{
//   width: 50% !important;
//   #about{
//       height:130px;
//   }
// }


.price-container {
  .ant-collapse-icon-position-start{
    .ant-collapse-item{
      .ant-collapse-header{
        // background-color: #F9F9F9;
        .ant-collapse-content{
          .ant-collapse-content-box{
            .ant-collapse{
              .ant-collapse-item{
                .ant-collapse-header{
                  padding-left: 50px;
                  background-color: #07BD81 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
// .ant-collapse-header  {
//   background-color: #F9F9F9;
//   .ant-collapse-header{
//     padding-left: 50px;
//   }
// }

.ant-collapse-content-box {
  background-color: #F9F9F9;
  padding: 0px !important;
}

.ant-collapse.ant-collapse-icon-position-start {
  border: none;
}

.ant-collapse-item {

  border-bottom: 1px solid #d9d9d9 !important;
}
  
.ant-collapse-header {
  // padding-left: 25px !important;
}

.coachmi-footer {
  display: flex;
  column-gap: 17px;

  .coachmi-primary-submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    // padding: 6px 12px !important;
    border-radius: 3px;
    width: 30% !important;
  }

  .coachmi-primary-cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 4px;
    // padding: 0 20px;
    width: 30% !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.pd-0 {
  padding: 0;
}

.mb-6 {
  margin-bottom: 6px;
}

.mt-6 {
 margin-top: 6px;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.br-4 {
  border-radius: 4px !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.ant-tooltip-inner {
  text-transform: capitalize !important;
}

.ant-tooltip-inner .capitalize {
  text-transform: capitalize !important;
}

.tag-green, .tag-red{
  margin: 0 !important;
}

.strike-price {
  text-decoration: line-through;
}

.tooltip-actual-price {
  display: flex;
  column-gap: 10px;
}
.subcripton{
  margin-bottom: 20px;
}
.header-container-app-details-container{
  display: flex;
}