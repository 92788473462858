.drawer-content{
  width: 100%;
  font-weight: 500;
  .ant-select{
    width: 100%;
  }
  .drawer-content-subject{
    padding: 15px 0px 7px 4px;
  }
  select{
    font-size: 14px;
    width: 100%;
    height: 40px;
    padding:10px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    option{
      height: 40px;
    }
  }
  Button{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.add-new-chapter{
  Button{
    font-size: 13px !important;
    font-weight: 500;
  }
}
.footer-button {
  padding: 4px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
