.input-field {
  flex: 1;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 5px 12px;
  border: 1px solid #B8C3C9;
  border-radius: 4px;
  font-size: 14px;
  width: 315px;
  margin-left: 15px;
}

.educator-profile {
  width: 24px !important;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.testmi-remove-container {
  display: flex;
  gap: 8px;
  width: 587px;
  flex-wrap: wrap;
  margin-bottom: 7px;
}

.base-remove-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #A3C5F5;
  border-radius: 7px;
  height: 44px;

  .remove-icon-wrapper {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    cursor: pointer;
  }

  &.youtube-link-remove {
    border: 1px solid #A3C5F5;
    background: #F4FAFF;
    margin-top: 7px;

    .link-container {
      display: flex;
      column-gap: 8px;
      margin-left: 12px;

      .youtube-link {
        max-width: 300px;

        span {
          font: 500 12px/19px 'Montserrat';
          text-decoration: underline;
          color: #3B82F6;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.attachment-remove {
    border: 1px solid #F8CBAB;
    background: #FFEDE0;
  }

  &.test-remove {
    width: 213px;
    height: 40px;
    border: 1px solid #07BD811A;
    background: #07BD811A;

    .vector-container {
      display: flex;
      column-gap: 8px;
      margin-left: 8px;

      .testmi-name {
        max-width: 130px;

        span {
          font: 500 12px/14px 'Montserrat';
        }
      }
    }

    .remove-icon-wrapper {
      margin-right: 3px;
    }
  }
}

.educator-name {
  font: 400 12px / 20px 'Montserrat';
  text-transform: capitalize;
}

.topicImage-icon {
  margin-right: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.icon {
  padding-left: 12px;
}

.topic-name,
.sub-topic-name,
.class-name,
.content-name {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font: 500 13px/20px 'Montserrat';
  text-transform: capitalize;
}

.form-control .other-video-content-input {
  height: 32px;
}

.content-thumbnail{
  display: flex;
  flex-direction: column;
  gap: 6px;
  // width: 30%;

  .Thumbnail {
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    color: #1B2228;
  }

  p {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #1B2228;
  }

  .thumbnail-content {
    border: 1px dashed #B1BFD0;
    display: flex;
    flex-direction: column;
    height: 150px;
    align-items: center;
    justify-content: center;

    .thumbnail-image {
      width: 44px;
      height: 44px;
    }
    .thumbnail-para{
    font-family: "Montserrat";
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    // text-align: center;
    }

    .thumbnail-para {
      font-family: "Montserrat";

    }

    .browse-image {
      cursor: pointer;
      color: #1F4690;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 2px;
    }
  }
}

.form-control .other-video-content-description {
  height: 112px;
}

.ant-popover-inner {
  position: relative;
  right: 80px;
}

.ant-popover-placement-top .ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  // height: 120px;
  height: 82px;
  width: 200px;
  overflow-y: scroll;

  p {
    height: 32px !important;
    padding: 9px;
    font-size: 14px;
    width: max-content;
    cursor: pointer;
    text-overflow: ellipsis;
    font-weight: normal;
  }
}

.recomented-control {
  .ant-select-multiple .ant-select-selection-item {
    height: 32px !important;
    line-height: 31px !important;
  }
}

// .save-btn {
//   background: #243978;
//   color: white;
//   padding: 5px 12px;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer !important;
//   text-decoration: none;
//   margin-left: 64px;
//   margin-right: 10px;
// }

.curriculam-content {
  overflow-y: scroll;
  height: calc(100vh - 202px);

  .curriculum-main-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    input {
      width: 320px;
      height: 32px;
    }

    .save-btn {
      margin-left: 60px;
      background: #243978;
      color: #fff;
      padding: 0px 12px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer !important;
      height: 28px;
    }

    .cancel-btn {
      margin-left: 30px;
      padding: 6px 12px;
      color: #0F6FDE;
      text-decoration: underline;
      cursor: pointer;
    }

    .topic-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: 7px;
    }

    .btn-input-btn {
      display: flex;
      align-items: center;
    }

    .topic-curriculam {
      background: #F9F9F9;

      .topic-content-setting {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 7px;
        border-bottom: 1px solid #B8C3C9;

        .topic-name {
          margin: 0px;
          font: 600 13px/20px 'Montserrat';
          text-align: left;
          text-transform: capitalize;
        }

        .topic-settig {
          display: flex;
          justify-content: center;
          align-items: center;

          .quick-action {
            width: 123px !important;
          }

          .topic-setting-icon {
            margin-left: 10px;

            img {
              padding: 0px !important;
              cursor: pointer;
            }
          }
        }
      }
    }


    .sub-topic-input-content {
      margin-left: 20px;
      margin-top: 7px;

      .sub-topic-content-setting {
        display: flex;
        justify-content: space-between;
        padding: 7px 0px 7px 0px;
        border-bottom: 1px solid #B8C3C9;
        align-items: center;

        .sub-topic-settig {
          display: flex;
          align-items: center;
          column-gap: 30px;

          .sub-topic-setting-icon {
            margin-left: 10px;

            img {
              padding: 0px !important;
              cursor: pointer;
            }
          }
        }
      }

      .class-content {
        margin-left: 20px;
        margin-top: 7px;

        .class-content-setting {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 0px 7px 0px;
          border-bottom: 1px solid #B8C3C9;

          .class-settig {
            display: flex;
            align-items: center;
            column-gap: 30px;

            .class-setting-icon {
              margin-left: 10px;

              img {
                padding: 0px !important;
                cursor: pointer;
              }
            }
          }
        }
      }

      .content-container {
        margin-left: 20px;
        margin-top: 7px;
      }

      .class-content-btn {
        display: flex;
        margin-left: 20px;

        .add-content-btn {
          margin-left: 20px;
        }
      }
    }

    .add-subtopic-btn-divition {
      margin-left: 20px;
    }

    .add-btn {
      color: #0F6FDE;
      font-size: 13px;
      cursor: pointer;
      margin-top: 10px;
      width: max-content;
      text-decoration: underline;
    }


    .publish-btn {
      width: 70px;
      height: 28px;
      // background: #243978; // enabled
      background-color: #CBD5E1; // disabled
      font: 500 12px/19px Montserrat;
      color: #fff;
      padding: 0 12px;
      border-radius: 3px;
      // cursor: pointer;
      cursor: not-allowed;
    }

    h3 {
      margin: 0px;
    }

    .add-topic-btn {
      width: max-content;
    }

  }
}

.attachment-upload {
  // margin-top: 7px;

  .ImageUpload {
    .ant-upload {
      // margin-top: 7px;
      width: 100%;

      .attachment-upload-button {
        border: dashed 2px #8181816b;
        width: 100%;

        .browse-and-upload {
          color: #2A569E;
          margin-left: 6px;
        }
      }
    }
  }
}

.recomented-control {

  .form-control-btn {
    margin-top: 6px;

    .attach-upload-button:hover,
    .attach-upload-button:focus {
      color: #42526E !important;
      border-color: #E1E7EA !important;
    }

    .attach-upload-button {
      display: flex;
      align-items: center;
      padding: 0px 5px 0 0px;
    }

    .form-switch-btn {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .ant-switch {
        margin-right: 14px;
      }
    }
  }

  .form-control-btn-educator {
    width: 50%;
  }
}

.testmi-modal {
  width: 800px !important;
  height: 440px !important;

  .heading-testmi-select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter {
      display: flex;
      align-items: center;

      .filter-select {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .filter-drop-down {
          width: 120px !important;
          height: 28px;

          .test-series-filter {
            font: 400 12px / 20px 'Montserrat';
          }
        }

        .label-name {
          margin-right: 10px;
          font: 500 12px/12px Montserrat;
          color: #37434E;
        }
      }
    }
  }

  .card-wrapper {
    height: 284px;
    overflow-y: scroll;

    .card-testmi {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      gap: 19px;
      margin-top: 30px;

      .card {
        width: 238px;
        height: 70px;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 8px;
        gap: 10px;
        cursor: pointer;

        &.checked {
          border: 1px solid #07BD81;
        }

        .title-name {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .names {
            display: flex;
            align-items: center;

            .test-type {
              margin-right: 5px;
              font: 500 10px/12px 'Montserrat';
              color: #D06200;
            }

            .test-group-name {
              // margin-left: 5px;
              color: #2D81F7;
              font: 500 10px/12px 'Montserrat';
              max-width: 140px;
            }
          }

          .ant-checkbox-input {
            width: 14px;
            height: 14px;
          }

          .ant-checkbox-inner {
            border-radius: 50% !important;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #07BD81 !important;
          }
        }

        .test-name {
          font: 500 12px / 14px 'Montserrat';
          color: #37434E;
          max-width: 196px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .details-test {
          font: 500 10px / 12px 'Montserrat';
          color: #37434E;
        }

        .details-test {
          display: flex;
          align-items: center;

          .number-question {
            padding-right: 10px;
            border-right: 1px solid #ddd;
          }

          .mark {
            margin-left: 10px;
            padding-right: 10px;
            border-right: 1px solid #ddd;
          }

          .min {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .ant-modal-body {
    padding: 21px !important;
  }

}

.price-plan-modal {
  .ant-modal-content {
    width: 460px;

    .ant-modal-header {
      .ant-modal-title {
        color: #1B2228;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
      }
    }

    .disabled-button {
      width: 30% !important;
    }

    .ant-modal-body {
      padding: 0px;

      .price-plan-section {
        .content-name {
          display: flex;
          align-items: center;
          padding: 12px 23px;
          background: #F4FAFF;

          .video-name {
            margin-left: 23px;
            font: 600 13px / 24px 'Montserrat';
            color: #021F55;
          }
        }

        .subscription-plan {
          padding: 15px;
          height: 238px;
          overflow-y: auto;

          .sub-plan-title {
            margin-bottom: 10px;
            color: #2A569E;
            font-size: 13px;
            font-family: 'Montserrat';
            font-weight: 600;
          }

          .price-plan-switch {
            margin-top: 7px;

            .form-switch-btn {
              display: flex;
              align-items: center;

              p {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}