.previous-year-price-plan {
  display: flex;
  align-items: center;
  margin: 6px 0px;

  .ant-radio-group {
    display: flex;
    margin-left: 20px;
  }
}

.form-control {
  padding: 0px;

  .input-label {
    display: flex;
    flex-direction: column;
    height: 55px;

    input {
      margin-bottom: 0px;
    }
  }

  .instuction {
    height: 100% !important;
  }
}