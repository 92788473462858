.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-container {
        display: flex;
        column-gap: 12px;
        align-items: center;

        .arrow-icon {
            cursor: pointer;
        }

        .stepper-title {
            font: 600 14px/20px Montserrat;
            text-align: left;
        }
        
    }

    .btn-container {
        display: flex;
        gap: 10px;

        .custom-submit-button, .custom-cancel-button {
            margin: 0 !important;
        }

    }
}