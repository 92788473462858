.institution-master-page {
  height: 100%;

  .institution-header {
    p {
      margin: 0px;
    }

    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .header-name {
      font-weight: 500;
      font-size: 18px;
      color: #37434E;
      text-transform: capitalize;
    }

    .create-button {
      background: #2D81F7;
      font-weight: 500;
      font-size: 13px;
      color: #FFFFFF;
    }
  }

  .institution-content {
    background: #FFFFFF;
    border-radius: 6px;
    height: 77%;

    .instituion-empty {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      p {
        margin: 0;
      }

      .instituion-empty-header {
        font-weight: 500;
        font-size: 24px;
        color: #1B2228;
        padding: 45px 0px 10px 0px;
      }

      .instituion-empty-content {
        font-weight: 500;
        font-size: 13px;
        color: #60717A;
        padding-bottom: 28px;
        padding-right: 20px;
      }

      .instituion-empty-button {
        font-weight: 500;
        font-size: 13px;
        color: #FFFFFF;
        background: #2D81F7;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
      }
    }

    .ant-drawer-content-wrapper {

      .institution-Drawer {
        width: 878px !important;
      }
    }
  }
}

.individual-institution {
  .institution-header {
    p {
      margin: 0px;
    }

    .header-wrapper {
      display: flex;
      align-items: baseline;

      .individual-institution-export {
        margin-right: 42.5px;
        cursor: pointer;

        span {
          padding-left: 6.5px;
        }
      }

      .individual-institution-import {
        cursor: pointer;
        margin-right: 63.25px;

        span {
          padding-left: 7.5px;
        }
      }
    }

    display: flex;
    justify-content: space-between;

    .header-name {
      font-weight: 600;
      font-size: 18px;
      color: #1B2228;
      margin: 6px 27px 20px 0px;
      text-transform: capitalize;
    }

    .create-button {
      background: #2D81F7;
      font-weight: 500;
      font-size: 13px;
      color: #FFFFFF;
      margin: 6px 27px 12px 0px;
    }
  }
}

.institutionDetails-content {
  display: flex;
  padding-top: 16px;

  .form-control {
    padding-bottom: 34px;
    position: relative;

    input {
      margin-bottom: 0px;
    }

    .error {
      position: absolute;
      font-size: 11px;
    }
    .ImageUpload {
      .ant-upload-list {
        .ant-upload-list-picture-container {
          width: 377px;
        }
      }
    }
  }

  input {
    width: 398px;
  }

}

.studentDetails-main-radioButton {
  display: flex;
  align-items: center;
  padding-top: 6px;

  .student-limit {
    width: 50%;
  }
}

.test-valid-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 828px;

  .test-valid-content {
    width: 398px;
  }
}

.subscription-details-content {
  padding-top: 16px;

  .form-control {
    width: 398px !important;
  }
}

.body-header {
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #021F55;
    // padding-bottom: 16px;
  }
}

.divider-line {

  border: 1px solid #E1E7EA;
  width: 828px;
  height: 0px;
  margin-top: 20px;
}


/* test batch list */
.batch-test-list {
  .batch-test-content {

    .assign-test-container {
      margin-top: 32px;
      position: relative;
      background-color: #fff;
      padding: 0px 20px 20px;


      .test-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .test-card-container {
        display: flex;
        flex-wrap: wrap;

        .test-card {
          background: #FFFFFF;
          border: 1px solid #B8C3C9;
          border-radius: 10px;
          padding: 14px;
          margin-right: 1%;
          width: 32%;
          margin-bottom: 20px;

          p {
            margin: 0px;
          }

          .test-exam-group-name {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #2D81F7;
            margin-bottom: 6px;
          }

          .test-name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #021F55;
            margin-bottom: 10px;
          }

          .test-details {

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            width: 75%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }

            .break {
              border: 1px solid #60717A;
              height: 13px;
              width: 1px;
            }
          }
          .time-duration {
            display: flex;
            align-items: center;

            .image {
              margin-right: 10px;
            }

            .time {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }
          }

          .button-section {
            .ant-btn {
              margin: 0px;
              margin-top: 8px;
            }

            .view-test-result-button {
              background: #07BD81 !important;
            }

            .testOnGoing {
              font-weight: 500;
              font-size: 13px;
              line-height: 15px;
              color: #37434E;
            }
          }
        }
      }
    }
  }
}

.empty-test-section{
  width: 100%;
  height: calc(100vh - 255px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .empty-image{
    text-align: center;
  }
  .empty-content{
    margin-top: 37px;
    p{
      color: #1B2228;
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

/* batch test result */
.batch-test-result-list {
  .heading-back-icon {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    .image {
      cursor: pointer;
      display: flex;
    }

    .test-name {
      margin-left: 8px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      cursor: text;
    }
  }

  .batch-test-result-content {
    padding: 20px;
    border-radius: 8px;
    background: #FFF;
    margin-bottom: 18px;

    .test-content {
      margin-bottom: 28px;

      .exam-group-name {
        font-size: 11px;
        font-weight: 500;
        color: #2D81F7;
        margin-bottom: 12px;
      }

      .test-name {
        font-size: 20px;
        font-weight: 600;
        color: #1B2228;
        margin-bottom: 12px;
      }

      .divition-tag {
        height: 2px;
        width: 100%;
        background: #E1E7EA;
        margin-bottom: 12px;
      }

      .test-details {
        display: flex;
        justify-content: space-between;
        width: 90%;

        .icon-section {
          display: flex;
          margin-top: 8px;

          p {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 500;
            color: #37434E;
          }
        }

        .mark-section {
          .marks {
            font-size: 25px;
            font-weight: 600;
            color: #1B2228;
          }

          .marks-label {
            font-size: 16px;
            font-weight: 500;
            color: #37434E;
          }
        }

        .mark-language {
          .total-student {
            font-size: 13px;
            font-weight: 500;
            color: #37434E;
          }
        }
      }
    }

    .mark-content {

      .section-1,
      .section-2 {
        display: flex;
        justify-content: space-between;

        .mark-card {
          padding: 9px 14px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          background: #FFF;
          border: 1px solid #E1E7EA;
          width: 30%;

          .text-section {
            margin-left: 20px;

            .label {
              font-size: 13px;
              color: #37434E;
              font-weight: 500;
            }

            .value {
              font-size: 24px;
              font-weight: 600;
              color: #1B2228;
            }
          }
        }
      }

      .section-1 {
        margin-bottom: 16px;
      }
    }
  }

  .Leader-board-content {
    margin-bottom: 20px;

    .heading {
      font-size: 16px;
      color: #1B2228;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .first-three-rank {
      display: flex;
      justify-content: start;
      margin-bottom: 16px;

      .rank-card {
        display: flex;
        padding: 0px 16px;
        border-radius: 6px;
        width: 32%;
        align-items: center;
        margin-right: 12px;

         
        

        .name-mark {
          // margin-left: 14px;

          .name {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 8px;
            color: #242424;
          }

          .mark {
            font-size: 24px;
            font-weight: 600;
            line-height: 20px;
            color: #1B2228;

            span {
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
              color: #60717A;
            }
          }
        }
      
      }

      .first-rank {
        background: rgba(45, 129, 247, 0.10);
      }

      .second-rank {
        background: #FEF9C3;
      }

      .third-rank {
        background: #E9D5FF;
       margin-right: 0;
      }
    }

    .table-section-rank {
      .ant-table-thead {}
    }
  }
}