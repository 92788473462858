.overall-homepage{
  height: 100%;
  display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #FFFFFF;
  .homepage-header{
    font-weight: 500;
    font-size: 24px;
    padding-top: 45px;
    color: #1B2228;
  }
  .homepage-content{
    font-weight: 500;
    font-size: 13px;
    padding-bottom: 28px;
  }
}

.test-bundle-header{
  display: flex;
  justify-content: space-between;
  .add-test-bundle{
    padding: 0px 40px 20px 0px;
  }
}